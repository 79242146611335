.right_title[data-v-1d1b5197] {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.right_title h3[data-v-1d1b5197] {
  font-size: 14px;
  padding-bottom: 10px;
}
.right_title h5[data-v-1d1b5197] {
  font-weight: normal;
}
.right_title p[data-v-1d1b5197] {
  font-size: 12px;
  color: red;
  text-align: left !important;
  padding: 5px;
}
.item_radio[data-v-1d1b5197],
.one_item[data-v-1d1b5197] {
  width: 100%;
  padding-left: 30px;
  height: 33px;
}
.item_lists[data-v-1d1b5197] {
  width: 100%;
  padding-left: 60px;
}
.el-radio-group[data-v-1d1b5197] {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}
.none-border p[data-v-1d1b5197] {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top_hint[data-v-1d1b5197] {
  color: orange;
  font-size: 13px;
  position: absolute;
  top: 12px;
  z-index: 999;
  left: 405px;
  display: flex;
  align-items: center;
}
.print_style[data-v-1d1b5197] {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 12px;
}
.orange[data-v-1d1b5197] {
  color: orange !important;
}